import './index.css';

var homeBanner = new Swiper('.home-banner-swiper', {
  loop: true,
  autoplay: {
    delay: 3200,
    disableOnInteraction: false
  },
  speed: 1200,
  pagination: {
    el: '.home-banner-swiper .swiper-pagination',
    clickable: true
  },
  on: {
    init: function () {
      swiperAnimateCache(this); //隐藏动画元素
      this.emit('slideChangeTransitionEnd'); //在初始化时触发一次slideChangeTransitionEnd事件
    },
    slideChangeTransitionEnd: function () {
      swiperAnimate(this); //每个slide切换结束时运行当前slide动画
    }
  }
});

var chooseToshibaSwiper = new Swiper('.choose-toshiba-swiper .swiper-container', {
  loop: true,
  autoplay: {
    delay: 3200,
    disableOnInteraction: false
  },
  speed: 1200,
  slidesPerView: 2.14,
  centeredSlides: true,
  pagination: {
    el: '.choose-toshiba-swiper .swiper-pagination',
    clickable: true
  },
  navigation: {
    nextEl: '.choose-toshiba-swiper .swiper-button-next',
    prevEl: '.choose-toshiba-swiper .swiper-button-prev'
  },
  breakpoints: {
    320: {
      //当屏幕宽度大于等于320
      slidesPerView: 1,
    },
    1024: {
      slidesPerView: 1
    },
    1025: {
      slidesPerView: 2.14
    }
  }
});
var newsEventsLeftSwiper = new Swiper('.news-events .left-swiper .swiper-container', {
  loop: true,
  speed: 1200,
});

var newsEventsRightSwiper = new Swiper('.news-events .right-swiper .swiper-container', {
  loop: true,
  speed: 1200,
  navigation: {
    nextEl: '.news-events .right-swiper .swiper-button-next',
    prevEl: '.news-events .right-swiper .swiper-button-prev',
  },
  controller: {
    control: newsEventsLeftSwiper,
  },
  on: {
    init: function () {
      swiperAnimateCache(this); //隐藏动画元素
      this.emit('slideChangeTransitionEnd');//在初始化时触发一次slideChangeTransitionEnd事件
    },
    slideChangeTransitionEnd: function () {
      swiperAnimate(this); //每个slide切换结束时运行当前slide动画
    }
  }
});
